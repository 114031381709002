import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Button, Row, Col, Card } from "react-bootstrap";

import { selectUserType } from "actions/userRegisterActions";

const SelectUserTypeCard = ({ selectUserType }) => {
  return (
    <Card className="card auth-card shadow-lg">
      <Card.Body className="card-body">
        <div className="px-3">
          <h2 className="text-center">You are a...</h2>
        </div>
        <Row>
          <Col className="mt-5">
            <Button onClick={() => selectUserType(1)} className="btn btn-primary btn-block" variant="primary" size="lg">
              Lieberman Employee
            </Button>
          </Col>
          <Col className="mt-5">
            <Button onClick={() => selectUserType(2)} className="btn btn-primary btn-block" variant="primary" size="lg">
              Lieberman Client
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ selectUserType }, dispatch);
};

export default connect(null, mapDispatchToProps)(SelectUserTypeCard);
